import AutoSizer from "react-virtualized-auto-sizer";

import { CheckIcon, CheckSuccessfulIcon } from "@/components/Icons";
import { useUserContext } from "@/contexts/UserContext";
import type { EntryResult, FantasyPointDetails, PlayerResult } from "@/types/Result";
import { cn } from "@/utils";
import { formatName } from "@/utils/name";
import { useRef, useState } from "react";
import { getScoreStyles } from "../Round/RoundInfo/FantasyPointChart";
import { PLAYER_STATS_DISPLAY_MAP } from "../Round/RoundInfo/constants";
import { setRankColor } from "./UserTable";

interface PlayerTabProps {
  players: PlayerResult[];
  chosenEntry: EntryResult;
}

export const PlayerTable = ({ players, chosenEntry }: PlayerTabProps) => {
  const [isOnlyYourTeam, setIsOnlyYourTeam] = useState(false);

  const user = useUserContext();
  const isYourEntry = chosenEntry.userId === user.id;

  const isCaptain = (playerId: string): boolean => {
    return chosenEntry.selectedCaptains.captainPlayerId === playerId;
  };

  const isViceCaptain = (playerId: string): boolean => {
    if (chosenEntry.selectedCaptains.type === "0002") {
      return chosenEntry.selectedCaptains.viceCaptainPlayerId === playerId;
    }
    return false;
  };

  const filteredPlayers =
    !isYourEntry || isOnlyYourTeam
      ? players.filter((player) => chosenEntry.selectedPlayers.includes(player.id))
      : players;

  return (
    <div className="pb-10 h-full flex flex-col">
      {isYourEntry && (
        <div
          className="flex gap-1 py-5 pb-3 hover:cursor-pointer w-fit"
          onClick={() => setIsOnlyYourTeam(!isOnlyYourTeam)}
        >
          {isOnlyYourTeam ? <CheckSuccessfulIcon className="w-4 h-4" /> : <CheckIcon className="w-4 h-4" />}
          <p className="text-xs text-icon">Show only your team</p>
        </div>
      )}

      <div className="flex-1">
        <AutoSizer>
          {({ height, width }) => (
            <div style={{ height, width }} className="overflow-y-scroll flex flex-col pt-2">
              {filteredPlayers
                .sort((a, b) => b.fantasyPoint - a.fantasyPoint)
                .map((player, index) => (
                  <PlayerRow
                    player={player}
                    rank={index + 1}
                    isInYourTeam={isYourEntry && chosenEntry.selectedPlayers.includes(player.id)}
                    isCaptain={isCaptain(player.id)}
                    isViceCaptain={isViceCaptain(player.id)}
                    key={player.id}
                  />
                ))}
            </div>
          )}
        </AutoSizer>
      </div>
    </div>
  );
};

interface PlayerRowProps {
  player: PlayerResult;
  rank: number;
  isInYourTeam: boolean;
  isCaptain: boolean;
  isViceCaptain: boolean;
}

const PlayerRow = ({ player, rank, isInYourTeam, isCaptain, isViceCaptain }: PlayerRowProps) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  return (
    <div
      className={cn(
        "flex items-start justify-start gap-4 w-full cursor-pointer text-icon px-4 py-1.5",
        isInYourTeam && "bg-gradient-to-r from-[#2A2A2A] to-[#2A2A2A]/0",
      )}
      onClick={() => setIsExpanded(!isExpanded)}
    >
      <div className="flex items-center gap-4">
        <p className={setRankColor(rank)}>{rank}</p>
        <div
          className="p-0.5 rounded-md"
          style={{
            padding: "1px",
            backgroundImage: isCaptain || isViceCaptain ? "linear-gradient(to bottom right, #FFE500, #00E218)" : "none",
            WebkitBackgroundClip: "padding-box",
            backgroundClip: "padding-box",
          }}
        >
          <div className="relative bg-chicago-700 rounded-md w-14 h-20">
            {(isCaptain || isViceCaptain) && (
              <div
                className="absolute top-0 left-0 px-3 py-0.5 text-sm font-bold text-black rounded-full z-10 flex items-center justify-center"
                style={{
                  width: "2.75rem",
                  backgroundImage: "linear-gradient(to bottom right, #FFE500, #00E218)",
                  transform: "translate(-25%, -50%)",
                }}
              >
                {isCaptain ? "C" : "VC"}
              </div>
            )}
            <img
              src={player.imageUrl || undefined}
              alt="player"
              className="w-full h-full rounded-md select-none cursor-pointer"
              draggable={false}
            />
          </div>
        </div>
      </div>

      <div className="flex-1">
        <div className="flex gap-2">
          <div className="flex flex-col gap-2 w-full">
            <div className="flex items-center gap-2">
              {isInYourTeam && <CheckSuccessfulIcon className="w-4 h-4 text-green-500" />}
              <p className="text-sm">{formatName(player.name)}</p>
            </div>
            <div
              className={`h-0.5 ${player.fantasyPoint < 0 ? "bg-red-500" : "bg-icon"}`}
              style={{
                width: `${Math.abs(player.fantasyPoint)}%`,
                boxShadow: rank === 1 ? "0px 0px 9px 0px #FF8A00" : "none",
              }}
            />
          </div>
          <p className={`flex justify-end w-[62px] ${rank === 1 ? "text-base" : "text-sm"}`}>
            {player.fantasyPoint.toFixed(2)}pt
          </p>
        </div>

        <div
          className="relative overflow-hidden transition-all duration-300 mt-14"
          style={{ maxHeight: isExpanded ? containerRef.current?.scrollHeight : 0 }}
          ref={containerRef}
          onKeyDown={() => {}}
        >
          {Object.keys(player.fantasyPointDetails).map((event: string) => {
            const stat = player.fantasyPointDetails[event as keyof FantasyPointDetails] ?? null;

            if (!stat || stat.occurrence === 0) {
              return null;
            }

            return (
              <div key={event} className="flex space-x-3 text-xs text-icon w-full py-1">
                <span className="flex-1">{PLAYER_STATS_DISPLAY_MAP[event]}</span>
                <span className="w-7 text-right">{stat.occurrence}</span>
                <span className={cn("w-14 text-right", getScoreStyles(stat.fantasyPoint))}>
                  {stat.fantasyPoint.toFixed(2)}pt
                </span>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
